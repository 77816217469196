import { React, useEffect, useState } from 'react';

import { FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskPhone } from '../../utils/maskCpfCnpj';

const AddClient = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [clientsList, setClientsList] = useState([]);
    const [apFolder, setApFolder] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [discount, setDiscount] = useState('');
    const [neoenergiaUF, setneoenergiaUF] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [copyEmail, setCopyEmail] = useState('');
    const [contactName, setContactName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [rows, setRows] = useState([]);

    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);

    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const columns = [
        {
            field: 'cc',
            headerName: 'CC',
            width: 150,
            editable: false
        },
        {
            field: 'address',
            headerName: 'Endereço',
            width: 150,
            editable: false
        },
        {
            field: 'consumption',
            headerName: 'Consumo',
            type: 'number',
            width: 110,
            editable: false,
            renderCell: (params) => (
                new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(params.value)
            )
        },
        {
            field: 'unitName',
            headerName: 'Unidade',
            width: 150,
            editable: true,
            required: true
        }
    ];

    useEffect(() => {
        async function getClients () {
            const response = await fetchFlask('getComercialClients');
            if (response.status !== 'error') {
                setClientsList(response.dados);
            } else {
                handleAlerta(response);
            }
        };
        getClients();
    }, []);

    const handleClientChange = async (e) => {
        const selectedClient = e.target.value;
        setApFolder(selectedClient);

        const body = JSON.stringify({ apFolder: selectedClient });
        const response = await fetchFlask('getClientData', body);

        if (response.status !== 'error') {
            setCompanyName(response.dados.companyName);
            setCpfCnpj(response.dados.cpfCnpj);
            setneoenergiaUF(response.dados.neoenergiaUF);
            setDiscount(response.dados.discount);
            setContactEmail(response.dados.contactEmail);
            setContactName(response.dados.contactName);
            setPhone1(maskPhone(response.dados.phone1));
            setPhone2(maskPhone(response.dados.phone2));

            const rowsFromDict = convertDictToRows(response.dados.units);
            setRows(rowsFromDict);
        } else {
            handleAlerta(response);
        }
    };

    const convertDictToRows = (dictUnits) => {
        return dictUnits.map((unit, index) => ({
            id: index + 1,
            cc: unit.cc,
            unitName: '',
            address: unit.address,
            consumption: unit.consumption
        }));
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    async function submitAddClient (e) {
        e.preventDefault();
        setIsSubmit(true);

        let beginDate = '';
        if (dateTime) {
            setError(false);
            beginDate = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }

        const missingUnitName = rows.filter(row => !row.unitName);
        if (missingUnitName.length > 0) {
            handleAlerta({ message: 'Preencha o nome de todas das unidades!', status: 'error' });
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            apFolder,
            beginDate,
            companyName,
            neoenergiaUF,
            cpfCnpj,
            discount,
            contactEmail,
            copyEmail,
            contactName,
            phone1,
            phone2,
            rows
        });

        const response = await fetchFlask('addClient', body);
        handleAlerta(response);
        setIsSubmit(false);
    };

    function handleAlerta (response) {
        const message = response?.message || 'An error occurred';
        const status = response?.status || 'error';
        setMessage(message);
        setStatus(status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />

            <form action='' method='POST' id='formNotificarCliente' onSubmit={submitAddClient}>
                <Grid container>
                    <Grid item xs={10}>
                        <Paper elevation={3} sx={{ padding: 2, marginTop: '20px' }}>

                            <Grid container sx={{ padding: 2, justifyContent: 'center' }}>
                                <Grid item xs={12}>
                                    <Typography variant='h3' sx={{ margin: '10px', textAlign: 'center' }}>
                                        Adicionar Cliente
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ display: 'flex' }}>
                                        <InputLabel id='labelCliente'>Cliente</InputLabel>
                                        <Select
                                            id='selectClient'
                                            labelId='labelClient'
                                            label='Client'
                                            value={apFolder}
                                            onChange={handleClientChange}
                                            required
                                        >
                                            {clientsList.map((item) => (
                                                <MenuItem key={item} value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {apFolder && (
                                <>
                                    <Grid container sx={{ padding: 2, justifyContent: 'center' }} spacing={2} >
                                        <Grid item xs={6}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Nome da Empresa'
                                                    value={companyName}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='CPF/CNPJ'
                                                    disabled={Boolean(cpfCnpj)}
                                                    value={cpfCnpj}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Desconto'
                                                    disabled={Boolean(discount)}
                                                    value={discount}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Nome'
                                                    disabled={Boolean(contactName)}
                                                    value={contactName}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Distribuidora'
                                                    disabled={Boolean(neoenergiaUF)}
                                                    value={neoenergiaUF}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Telefone'
                                                    disabled={Boolean(phone1)}
                                                    value={phone1}
                                                />
                                            </FormControl>
                                        </Grid>

                                        {phone2 && (
                                            <>
                                                <Grid item xs={3}>
                                                    <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                        <TextField
                                                            label='Telefone 2'
                                                            disabled={Boolean(phone2)}
                                                            value={phone2}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}

                                        <Grid item xs={5}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Email'
                                                    disabled={Boolean(contactEmail)}
                                                    value={contactEmail}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <TextField
                                                    label='Email Cópia'
                                                    value={copyEmail}
                                                    onChange={(e) => setCopyEmail(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', margin: '10px' }}>
                                                <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                                    <DatePicker id='data' label='Data de Início'
                                                        minDate={new Date('2023-01-02')}
                                                        value={dateTime}
                                                        onChange={(value) => setDateTime(value)}
                                                        slotProps={{
                                                            openPickerButton: {
                                                                color: 'primary'
                                                            }
                                                        }}
                                                    />
                                                    {error && (
                                                        <Typography variant='caption' color='red' sx={{ display: 'flex', marginLeft: '20px' }}>
                                                            Por favor selecione uma data!
                                                        </Typography>
                                                    )}
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper elevation={1} sx={{ backgroundColor: '#f0f1f383', marginTop: '10px', width: '100%' }}>
                                                <Box sx={{ height: 250 }}>
                                                    <DataGrid
                                                        rows={rows}
                                                        columns={columns}
                                                        processRowUpdate={processRowUpdate}
                                                        localeText={{ noRowsLabel: 'Sem Unidades' }}
                                                        hideFooter
                                                    />
                                                </Box>
                                            </Paper>
                                        </Grid>

                                        <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center', marginTop: '10px' }}>
                                            <Grid item xs={6}>
                                                <FormControl sx={{ display: 'flex' }}>
                                                    <SubmitButton isSubmit={isSubmit} >Adicionar Cliente</SubmitButton>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AddClient;
