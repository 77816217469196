import { React, useContext, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { Alerta, PopupDialog } from '../components/alerta';
import { MenuButton, MenuClickButton } from '../components/menuButton';
import styles from '../pages/css/menu.module.css';
import DadosUsuario from '../utils/context';
import { fetchFlask } from '../utils/fetchFlask';

const Menu = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [textoDialog, setTextoDialog] = useState({});
    const [openAlerta, setOpenAlerta] = useState(false);
    const dadosUsuario = useContext(DadosUsuario);
    const autorizacoes = dadosUsuario.autorizacoes;

    function handleButtonClick (e, titulo, texto, funcao) {
        e.preventDefault();
        setOpenDialog(true);
        setTextoDialog({ titulo, texto, funcao });
    }

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    async function submitFaturasNeoenergia () {
        const response = await fetchFlask('lerFaturasNeoenergia');
        handleAlerta(response);
    };

    return (
            <Box className={styles.row}>
                <Alerta
                    texto={message}
                    status={status}
                    open={openAlerta}
                    handleClose={() => setOpenAlerta(false)}
                />
                <PopupDialog
                    titulo={textoDialog.titulo}
                    texto={textoDialog.texto}
                    open={openDialog}
                    handleClose={() => setOpenDialog(false)}
                    funcao={textoDialog.funcao}
                />
                {(autorizacoes.includes('OeM') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>O&M</Typography>
                        <MenuButton pagina='OeM/inserirFalhas' texto='Inserir Falhas' />
                        <MenuButton pagina='OeM/inserirGeracao' texto='Inserir Geração' />
                        <MenuButton pagina='OeM/substituirInversor' texto='Substituir Inversor' />
                        <MenuButton pagina='OeM/atualizarExtintores' texto='Atualizar Extintores' />
                        <MenuButton pagina='OeM/atualizarNRs' texto='Atualizar NRs' />
                        <MenuButton pagina='OeM/atualizarVegetacao' texto='Atualizar Vegetação' />
                        <MenuButton pagina='OeM/criarRelatorioSPEs' texto='Relatório SPEs' />
                        <MenuButton pagina='OeM/alertasPVOPeration' texto='Alertas PV OP' />
                        <MenuButton pagina='OeM/desativarReativarInversor' texto='Desativar/Reativar Inversor' />
                    </Box>
                )}

                {(autorizacoes.includes('Protocolos') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Protocolos</Typography>
                        <MenuButton pagina='Protocolos/inserirProtocolos' texto='Inserir Protocolos' />
                        <MenuButton pagina='Protocolos/finalizarProtocolo' texto='Finalizar Protocolos' />
                        <MenuButton pagina='Protocolos/prorrogarProtocolo' texto='Prorrogar Protocolos' />
                    </Box>
                )}

                {(autorizacoes.includes('GE') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>G.E</Typography>
                        <MenuButton pagina='GestaoEnergia/calcularAluguel' texto='Calcular Aluguel' />
                        <MenuButton pagina='GestaoEnergia/distrato' texto='Distrato' sx={{ width: '100% !important' }} />
                        <MenuButton pagina='GestaoEnergia/cadastrarCC' texto='Cadastrar CC Consórcio' />
                        <MenuButton pagina='GestaoEnergia/addAdditive' texto='Adicionar Aditivo ou CC' />
                        <MenuButton pagina='GestaoEnergia/adicionarCC' texto='Adicionar CC Celpe' />
                        <MenuButton pagina='GestaoEnergia/emailTrocaTitularidade' texto='Email Troca de Titularidade' />
                        <MenuButton pagina='GestaoEnergia/notificarCliente' texto='Notificar Cliente' />
                        <MenuButton pagina='GestaoEnergia/userFakePortal' texto='Copiar Usuario Portal' />
                        <MenuButton pagina='GestaoEnergia/AluguelMataraca' texto='Aluguel Mataraca' />
                        <MenuButton pagina='GestaoEnergia/atualizarSiteDoacao' texto='Atualizar Site Doação' />
                        <MenuButton pagina='GestaoEnergia/gerarCSVFaturamento' texto='Gerar CSV Faturamento' />
                        <MenuButton pagina='GestaoEnergia/gerarFechamento' texto='Gerar Fechamento' />
                        <MenuButton pagina='GestaoEnergia/listarCCsConsorcio' texto='Listar CCs' />
                        <MenuButton pagina='GestaoEnergia/listarDebitos' texto='Listar Débitos' />
                        <MenuClickButton
                            funct={(e) => handleButtonClick(e, 'Leitura de Faturas',
                                'Tem certeza que deseja realizar a leitura de faturas e enviar o CSV correspondente?', submitFaturasNeoenergia)}
                            texto='Leitura de Faturas' />
                    </Box>
                )}

                {(autorizacoes.includes('Financeiro') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Financeiro</Typography>
                        <MenuButton pagina='Financeiro/faturarCliente' texto='Faturar Cliente' />
                        <MenuButton pagina='Financeiro/cancelarOS' texto='Cancelar OS' />
                        <MenuButton pagina='Financeiro/criarContrato' texto='Criar Contrato' />
                        <MenuButton pagina='Financeiro/prorrogarBoleto' texto='Prorrogar Boleto' />
                        <MenuButton pagina='Financeiro/gerarPix' texto='Gerar Pix' />
                    </Box>
                )}

                {(autorizacoes.includes('Comercial') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Comercial</Typography>
                        <MenuButton pagina='Comercial/addAdditive' texto='Adicionar Aditivo ou CC' />
                        <MenuButton pagina='Comercial/adicionarCC' texto='Adicionar CC' />
                        <MenuButton pagina='Comercial/alterarContato' texto='Alterar Contato' />
                        <MenuButton pagina='Comercial/prorrogarBoleto' texto='Prorrogar Boleto' />
                        <MenuButton pagina='Comercial/notificarCliente' texto='Notificar Cliente' />
                        <MenuButton pagina='Comercial/userFakePortal' texto='Copiar Usuario Portal' />
                        <MenuButton pagina='Comercial/emailVerificacao' texto='E-mail Verificação' />
                    </Box>
                )}

                {(autorizacoes.includes('Externo') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Comercial Externo</Typography>
                        <MenuButton pagina='Externo/comercialCRM' texto='Comercial (Proposta/Contato)' />
                    </Box>
                )}

                {(autorizacoes.includes('Contabilidade') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Contabilidade</Typography>
                        <MenuButton pagina='Contabilidade/startEC2' texto='Ligar Servidor' />
                        <MenuButton pagina='Contabilidade/changeSchedule' texto='Alterar Horário Servidor' />
                    </Box>
                )}

                {(autorizacoes.includes('Interno') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Comercial Interno</Typography>
                        <MenuButton pagina='Interno/addClient' texto='Adicionar Cliente' />
                        <MenuButton pagina='Interno/CreateDocuments' texto='Criar Documentos Comercial' />
                        <MenuButton pagina='Interno/addAdditive' texto='Adicionar Aditivo ou CC' />
                        <MenuButton pagina='Interno/adicionarCC' texto='Adicionar CC' />
                        <MenuButton pagina='Interno/cadastrarLeadCRM' texto='Cadastrar Lead' />
                        <MenuButton pagina='Interno/gerarProposta' texto='Proposta Digital' />
                    </Box>
                )}

                {(autorizacoes.includes('Consultor') || autorizacoes.includes('Adm')) && (
                    <Box className={styles.column}>
                        <Typography variant='h4'>Consultor Comercial</Typography>
                        <MenuButton pagina='Consultor/gerarProposta' texto='Proposta Digital' />
                    </Box>
                )}
            </Box>
    );
};

export default Menu;
